@tailwind base;
@tailwind components;
@tailwind utilities;

@media only screen and (max-width: 767px) {
  .pdf-hidden {
    display: none;
  }
}

.speedometer-container {
  width: 100%;
  padding: 0 20px;
}

@media screen and (max-width: 768px) {
  .speedometer-container {
    width: 80%;
  }
}

@media screen and (max-width: 480px) {
  .speedometer-container {
    width: 90%;
  }
}
